import {
  AppShell,
  Box,
  Button,
  Checkbox,
  Container,
  Notification,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Alert,
  MantineProvider,
  Radio,
} from "@mantine/core";
import { isEmail, isNotEmpty, matches, useForm } from "@mantine/form";
import { FaAt, FaExclamation, FaPhone, FaUser } from "react-icons/fa";

import { useSetState } from "@mantine/hooks";
import React from "react";
import { TravelucaFooter } from "src/components/footer";
import { TravelucaHeader } from "src/components/header";
import { ChatGPTOrder } from "src/models";
import { submitChatGPTOrder } from "src/repositories/order";
import { getChatGPTProduct } from "src/repositories/products";
import { rupiah } from "src/utils/currency";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  profession: "",
  isSwearing: false,
  payCarbonFootprint: false,
  knowFrom: "",
};

const validateFn = {
  firstName: isNotEmpty("Masukkan nama depan"),
  lastName: isNotEmpty("Masukkan nama belakang"),
  email: isEmail("Masukan email yang valid"),
  phoneNumber: matches(
    /^(\+62|62)?[\s-]?0?8[1-9]{1}\d{1}[\s-]?\d{4}[\s-]?\d{2,5}$/,
    // /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    "Masukan nomor telepon yang valid"
  ),
  knowFrom: isNotEmpty("Pilih salah satu"),
};

export const OrderPage: React.FC = () => {
  const inputRadius = "md";

  const [state, setState] = useSetState({
    loading: false,
    success: true,
    error: false,
    chatGPTItem: {
      id: "chatgpt",
      name: "ChatGPT",
      price: 120000,
    },
  });

  React.useEffect(() => {
    getChatGPTProduct().then((res) => {
      if (res.id == "") {
        return
      }

      setState({
        chatGPTItem: {
          id: res.id,
          name: res.name,
          price: res.price,
        },
      })
    })    
  }, [])

  const form = useForm({
    initialValues,
    validate: validateFn,
  });

  const handleSubmit = form.onSubmit(async (values) => {
    let phoneNumberFmt = values.phoneNumber;

    setState({
      error: false,
      success: false,
      loading: true,
    });

    if (values.phoneNumber.startsWith("0")) {
      phoneNumberFmt = "+62" + values.phoneNumber.slice(1);
    }

    if (values.phoneNumber.startsWith("62")) {
      phoneNumberFmt = "+" + values.phoneNumber;
    }

    const order: ChatGPTOrder = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: phoneNumberFmt,
      profession: values.profession,
      carbon_footprint: values.payCarbonFootprint,
      know_from: values.knowFrom,
    };

    try {
      const res = await submitChatGPTOrder(order);
      setState({ success: true });
      window.location.replace(res.invoice_url);
    } catch (error) {
      console.log(error);
      setState({
        error: true,
        loading: false,
      });
    }
  });

  return (
    <MantineProvider>
      <AppShell
        header={<TravelucaHeader />}
        footer={<TravelucaFooter />}
        styles={(theme) => ({
          main: { backgroundColor: theme.colors.gray[1] },
        })}
      >
        <Container
          size="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {state.error && (
            <Notification
              icon={<FaExclamation />}
              title="Pemesanan Gagal"
              color="red"
              onClose={() => setState({ error: false })}
            >
              Terjadi kesalahan saat menghubungi server
            </Notification>
          )}
          <Paper
            mt="lg"
            p="lg"
            shadow="md"
            radius="lg"
            sx={{
              width: "100%",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box mb="xl">
                <Text weight="bold" size="xl" align="center">
                  Form pembelian akun ChatGPT Plus
                </Text>
              </Box>
              <Stack spacing="lg">
                <Alert icon={<FaExclamation size="1rem" />} title="Perhatian!">
                  Mau coba produk AI ClaudeAI/Perplexity?
                  <br /> <a href="/order">Klik ini</a> untuk langganan!
                </Alert>
                <SimpleGrid cols={2}>
                  <TextInput
                    radius={inputRadius}
                    withAsterisk
                    label="Nama Depan"
                    {...form.getInputProps("firstName")}
                  />
                  <TextInput
                    radius={inputRadius}
                    withAsterisk
                    label="Nama Belakang"
                    {...form.getInputProps("lastName")}
                  />
                </SimpleGrid>
                <TextInput
                  radius={inputRadius}
                  withAsterisk
                  label="Email"
                  type="email"
                  icon={<FaAt />}
                  placeholder="Akun dikirimkan ke email ini"
                  {...form.getInputProps("email")}
                />
                <TextInput
                  radius={inputRadius}
                  withAsterisk
                  type="text"
                  label="Nomor Telepon"
                  icon={<FaPhone />}
                  {...form.getInputProps("phoneNumber")}
                />
                <TextInput
                  radius={inputRadius}
                  type="text"
                  label="Profesi (opsional)"
                  icon={<FaUser />}
                  {...form.getInputProps("profession")}
                />
                <Radio.Group
                  name="knowFrom"
                  label="Tau produk ini dari mana?"
                  withAsterisk
                  {...form.getInputProps("knowFrom")}
                >
                  <Radio value="renew" label="Renew" />
                  <Radio value="tau dari teman" label="Tau dari teman" />
                  <Radio value="brosur/poster" label="Brosur/Poster" />
                  <Radio value="twitter" label="Twitter" />
                </Radio.Group>
                { /*<Checkbox
                  label="Pay Rp1.500 to offset 10 kgCO2e of your CO2e emission (Powered by CarbonShare)"
                  {...form.getInputProps("payCarbonFootprint", {
                    type: "checkbox",
                  })}
                /> */}
                <Checkbox
                  label="Saya bersumpah, tidak akan mengubah password, membagikan/menjual kembali akun ini untuk keuntungan pribadi, dan tidak akan menghapus riwayat obrolan pengguna lain."
                  {...form.getInputProps("isSwearing", { type: "checkbox" })}
                />
                <Button
                  radius={inputRadius}
                  loading={state.loading}
                  disabled={!form.values.isSwearing}
                  type="submit"
                >
                  Beli akun ChatGPT Plus - {rupiah(state.chatGPTItem.price)}
                </Button>
                <Paper withBorder p="xs" radius="md">
                  <Text size="xs">
                    Your order and data will be processed and shared with
                    lucacadalora.com under PT Traveluca Sejahtera Bersama for
                    product fulfillment and PT Sinar Digital Terdepan
                    (Xendit.co) as online payment gateway services
                  </Text>
                </Paper>
              </Stack>
            </form>
          </Paper>
        </Container>
      </AppShell>
    </MantineProvider>
  );
};
