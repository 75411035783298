import { APIResponse, ChatGPTOrder, GenericGPTOrder, OrderSuccessResponse } from "src/models";
import api from "../utils/apiClient";

export const submitChatGPTOrder = async (
  order: ChatGPTOrder
): Promise<OrderSuccessResponse> => {
  try {
    const res = await api.post<APIResponse<OrderSuccessResponse>>(
      "v1/payment/chatgpt",
      order
    );
    return res.data.data;
  } catch (e) {
    throw e;
  }
};

export const submitGenericOrder = async (
  order: GenericGPTOrder
): Promise<OrderSuccessResponse> => {
  try {
    const res = await api.post<APIResponse<OrderSuccessResponse>>(
      "v1/payment/generic",
      order
    );
    return res.data.data;
  } catch (e) {
    throw e;
  }
};
