import { BrowserRouter, Routes, Route } from "react-router-dom";
import { OrderPage } from "./modules/order/OrderPage";
import { GenericOrderPage } from "./modules/order/GenericOrderPage";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<OrderPage />}/>
        <Route path="/order" element={<GenericOrderPage />}/>
      </Routes>
    </BrowserRouter>
  );
}
