import { APIResponse, GetProductListResponse, GetProductResponse  } from "src/models";
import api from "../utils/apiClient";

export const getGenericProducts = async (
): Promise<GetProductListResponse> => {
  try {
    const res = await api.get<APIResponse<GetProductListResponse>>(
      "v1/products/generic",
    );
    return res.data.data;
  } catch (e) {
    throw e;
  }
};

export const getChatGPTProduct = async (
): Promise<GetProductResponse> => {
  try {
    const res = await api.get<APIResponse<GetProductResponse>>(
      "v1/products/chatgpt",
    );
    return res.data.data;
  } catch (e) {
    throw e;
  }
};