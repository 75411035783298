import {
  AppShell,
  Alert,
  Box,
  Button,
  Select,
  Container,
  Notification,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  MantineProvider,
} from "@mantine/core";
import { isEmail, isNotEmpty, matches, useForm } from "@mantine/form";
import { FaAt, FaExclamation, FaPhone, FaUser } from "react-icons/fa";

import { useSetState } from "@mantine/hooks";
import React from "react";
import { TravelucaFooter } from "src/components/footer";
import { TravelucaHeader } from "src/components/header";
import { GenericGPTOrder } from "src/models";
import { submitGenericOrder } from "src/repositories/order";
import { getGenericProducts } from "src/repositories/products";
import { rupiah } from "src/utils/currency";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  profession: "",
  product: "",
};

const validateFn = {
  firstName: isNotEmpty("Masukkan nama depan"),
  lastName: isNotEmpty("Masukkan nama belakang"),
  email: isEmail("Masukan email yang valid"),
  product: isNotEmpty("Pilih salah satu produk langganan"),
  phoneNumber: matches(
    /^(\+62|62)?[\s-]?0?8[1-9]{1}\d{1}[\s-]?\d{4}[\s-]?\d{2,5}$/,
    // /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
    "Masukan nomor telepon yang valid"
  ),
};

const defaultProducts = [
  {
    value: "claudeai",
    label: "ClaudeAI Subscription - Rp 120.000",
  },
  {
    value: "perplexity",
    label: "Perplexity Subscription - Rp 120.000",
  },
];

export const GenericOrderPage: React.FC = () => {
  const inputRadius = "md";

  const [state, setState] = useSetState({
    loading: false,
    success: true,
    error: false,
    products: defaultProducts,
  });

  React.useEffect(() => {
    getGenericProducts().then((res) => {
      if (res.length == 0) {
        return
      }

      const productRes: {value: string, label: string}[] = [];
      res.forEach(e => {
        productRes.push({
          value: e.id,
          label: `${e.name} Subscription - ${rupiah(e.price)}`
        })
      });
      setState({
        products: productRes,
      })
    })    
  }, [])

  const form = useForm({
    initialValues,
    validate: validateFn,
  });

  const handleSubmit = form.onSubmit(async (values) => {
    let phoneNumberFmt = values.phoneNumber;

    setState({
      error: false,
      success: false,
      loading: true,
    });

    if (values.phoneNumber.startsWith("0")) {
      phoneNumberFmt = "+62" + values.phoneNumber.slice(1);
    }

    if (values.phoneNumber.startsWith("62")) {
      phoneNumberFmt = "+" + values.phoneNumber;
    }

    const order: GenericGPTOrder = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: phoneNumberFmt,
      profession: values.profession,
      product: values.product,
    };

    try {
      const res = await submitGenericOrder(order);
      setState({ success: true });
      window.location.replace(res.invoice_url);
    } catch (error) {
      console.log(error);
      setState({
        error: true,
        loading: false,
      });
    }
  });

  return (
    <MantineProvider>
      <AppShell
        header={<TravelucaHeader />}
        footer={<TravelucaFooter />}
        styles={(theme) => ({
          main: { backgroundColor: theme.colors.gray[1] },
        })}
      >
        <Container
          size="xs"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {state.error && (
            <Notification
              icon={<FaExclamation />}
              title="Pemesanan Gagal"
              color="red"
              onClose={() => setState({ error: false })}
            >
              Terjadi kesalahan saat menghubungi server
            </Notification>
          )}
          <Paper
            mt="lg"
            p="lg"
            shadow="md"
            radius="lg"
            sx={{
              width: "100%",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box mb="xl">
                <Text weight="bold" size="xl" align="center">
                  Form pembelian akun langganan
                </Text>
              </Box>
              <Stack spacing="lg">
              <Alert icon={<FaExclamation size="1rem" />} title="Perhatian!">
                Mau berlangganan ChatGPT Plus saja?<br /> <a href="/">Klik ini</a> untuk langganan!
              </Alert>
                <SimpleGrid cols={2}>
                  <TextInput
                    radius={inputRadius}
                    withAsterisk
                    label="Nama Depan"
                    {...form.getInputProps("firstName")}
                  />
                  <TextInput
                    radius={inputRadius}
                    withAsterisk
                    label="Nama Belakang"
                    {...form.getInputProps("lastName")}
                  />
                </SimpleGrid>
                <TextInput
                  radius={inputRadius}
                  withAsterisk
                  label="Email"
                  type="email"
                  placeholder="Akun dikirimkan ke email ini"
                  icon={<FaAt />}
                  {...form.getInputProps("email")}
                />
                <TextInput
                  radius={inputRadius}
                  withAsterisk
                  type="text"
                  label="Nomor Telepon"
                  icon={<FaPhone />}
                  {...form.getInputProps("phoneNumber")}
                />
                <Select
                  label="Pilih langganan"
                  withAsterisk
                  placeholder="Pilih satu"
                  data={state.products}
                  {...form.getInputProps("product")}
                />
                <Button
                  radius={inputRadius}
                  loading={state.loading}
                  type="submit"
                >
                  Subscribe
                </Button>
              </Stack>
            </form>
          </Paper>
        </Container>
      </AppShell>
    </MantineProvider>
  );
};
